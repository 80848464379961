window.slate = window.slate || {};
window.theme = window.theme || {};

/*================ Slate ================*/
/**
 * A11y Helpers
 * -----------------------------------------------------------------------------
 * A collection of useful functions that help make your theme more accessible
 * to users with visual impairments.
 *
 *
 * @namespace a11y
 */

slate.a11y = {

  /**
   * For use when focus shifts to a container rather than a link
   * eg for In-page links, after scroll, focus shifts to content area so that
   * next `tab` is where user expects if focusing a link, just $link.focus();
   *
   * @param {JQuery} $element - The element to be acted upon
   */
  pageLinkFocus: function($element) {
    var focusClass = 'js-focus-hidden';

    $element.first()
    .attr('tabIndex', '-1')
    .focus()
    .addClass(focusClass)
    .one('blur', callback);

    function callback() {
      $element.first()
      .removeClass(focusClass)
      .removeAttr('tabindex');
    }
  },

  /**
   * If there's a hash in the url, focus the appropriate element
   */
  focusHash: function() {
    var hash = window.location.hash;

    // is there a hash in the url? is it an element on the page?
    if (hash && document.getElementById(hash.slice(1))) {
      this.pageLinkFocus($(hash));
    }
  },

  /**
   * When an in-page (url w/hash) link is clicked, focus the appropriate element
   */
  bindInPageLinks: function() {
    $('a[href*=#]').on('click', function(evt) {
      this.pageLinkFocus($(evt.currentTarget.hash));
    }.bind(this));
  },

  /**
   * Traps the focus in a particular container
   *
   * @param {object} options - Options to be used
   * @param {jQuery} options.$container - Container to trap focus within
   * @param {jQuery} options.$elementToFocus - Element to be focused when focus leaves container
   * @param {string} options.namespace - Namespace used for new focus event handler
   */
  trapFocus: function(options) {
    var eventName = options.namespace
    ? 'focusin.' + options.namespace
    : 'focusin';

    if (!options.$elementToFocus) {
      options.$elementToFocus = options.$container;
    }

    options.$container.attr('tabindex', '-1');
    options.$elementToFocus.focus();

    $(document).on(eventName, function(evt) {
      if (options.$container[0] !== evt.target && !options.$container.has(evt.target).length) {
        options.$container.focus();
      }
    });
  },

  /**
   * Removes the trap of focus in a particular container
   *
   * @param {object} options - Options to be used
   * @param {jQuery} options.$container - Container to trap focus within
   * @param {string} options.namespace - Namespace used for new focus event handler
   */
  removeTrapFocus: function(options) {
    var eventName = options.namespace
    ? 'focusin.' + options.namespace
    : 'focusin';

    if (options.$container && options.$container.length) {
      options.$container.removeAttr('tabindex');
    }

    $(document).off(eventName);
  }
};

/**
 * Cart Template Script
 * ------------------------------------------------------------------------------
 * A file that contains scripts highly couple code to the Cart template.
 *
 * @namespace cart
 */

slate.cart = {

  /**
   * Browser cookies are required to use the cart. This function checks if
   * cookies are enabled in the browser.
   */
  cookiesEnabled: function() {
    var cookieEnabled = navigator.cookieEnabled;

    if (!cookieEnabled){
      document.cookie = 'testcookie';
      cookieEnabled = (document.cookie.indexOf('testcookie') !== -1);
    }
    return cookieEnabled;
  }
};

/**
 * Utility helpers
 * -----------------------------------------------------------------------------
 * A collection of useful functions for dealing with arrays and objects
 *
 * @namespace utils
 */

slate.utils = {

  /**
   * Return an object from an array of objects that matches the provided key and value
   *
   * @param {array} array - Array of objects
   * @param {string} key - Key to match the value against
   * @param {string} value - Value to get match of
   */
  findInstance: function(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
  },

  /**
   * Remove an object from an array of objects by matching the provided key and value
   *
   * @param {array} array - Array of objects
   * @param {string} key - Key to match the value against
   * @param {string} value - Value to get match of
   */
  removeInstance: function(array, key, value) {
    var i = array.length;
    while(i--) {
      if (array[i][key] === value) {
        array.splice(i, 1);
        break;
      }
    }

    return array;
  },

  /**
   * _.compact from lodash
   * Remove empty/false items from array
   * Source: https://github.com/lodash/lodash/blob/master/compact.js
   *
   * @param {array} array
   */
  compact: function(array) {
    var index = -1;
    var length = array == null ? 0 : array.length;
    var resIndex = 0;
    var result = [];

    while (++index < length) {
      var value = array[index];
      if (value) {
        result[resIndex++] = value;
      }
    }
    return result;
  },

  /**
   * _.defaultTo from lodash
   * Checks `value` to determine whether a default value should be returned in
   * its place. The `defaultValue` is returned if `value` is `NaN`, `null`,
   * or `undefined`.
   * Source: https://github.com/lodash/lodash/blob/master/defaultTo.js
   *
   * @param {*} value - Value to check
   * @param {*} defaultValue - Default value
   * @returns {*} - Returns the resolved value
   */
  defaultTo: function(value, defaultValue) {
    return (value == null || value !== value) ? defaultValue : value
  }
};

/**
 * Rich Text Editor
 * -----------------------------------------------------------------------------
 * Wrap iframes and tables in div tags to force responsive/scrollable layout.
 *
 * @namespace rte
 */

slate.rte = {
  /**
   * Wrap tables in a container div to make them scrollable when needed
   *
   * @param {object} options - Options to be used
   * @param {jquery} options.$tables - jquery object(s) of the table(s) to wrap
   * @param {string} options.tableWrapperClass - table wrapper class name
   */
  wrapTable: function(options) {
    var tableWrapperClass = typeof options.tableWrapperClass === "undefined" ? '' : options.tableWrapperClass;

    options.$tables.wrap('<div class="' + tableWrapperClass + '"></div>');
  },

  /**
   * Wrap iframes in a container div to make them responsive
   *
   * @param {object} options - Options to be used
   * @param {jquery} options.$iframes - jquery object(s) of the iframe(s) to wrap
   * @param {string} options.iframeWrapperClass - class name used on the wrapping div
   */
  wrapIframe: function(options) {
    var iframeWrapperClass = typeof options.iframeWrapperClass === "undefined" ? '' : options.iframeWrapperClass;

    options.$iframes.each(function() {
      // Add wrapper to make video responsive
      $(this).wrap('<div class="' + iframeWrapperClass + '"></div>');

      // Re-set the src attribute on each iframe after page load
      // for Chrome's "incorrect iFrame content on 'back'" bug.
      // https://code.google.com/p/chromium/issues/detail?id=395791
      // Need to specifically target video and admin bar
      this.src = this.src;
    });
  }
};

slate.Sections = function Sections() {
  this.constructors = {};
  this.instances = [];

  $(document)
  .on('shopify:section:load', this._onSectionLoad.bind(this))
  .on('shopify:section:unload', this._onSectionUnload.bind(this))
  .on('shopify:section:select', this._onSelect.bind(this))
  .on('shopify:section:deselect', this._onDeselect.bind(this))
  .on('shopify:section:reorder', this._onReorder.bind(this))
  .on('shopify:block:select', this._onBlockSelect.bind(this))
  .on('shopify:block:deselect', this._onBlockDeselect.bind(this));
};

slate.Sections.prototype = $.extend({}, slate.Sections.prototype, {
  _createInstance: function(container, constructor) {
    var $container = $(container);
    var id = $container.attr('data-section-id');
    var type = $container.attr('data-section-type');

    constructor = constructor || this.constructors[type];

    if (typeof constructor === 'undefined') {
      return;
    }

    var instance = $.extend(new constructor(container), {
      id: id,
      type: type,
      container: container
    });

    this.instances.push(instance);
  },

  _onSectionLoad: function(evt) {
    var container = $('[data-section-id]', evt.target)[0];
    if (container) {
      this._createInstance(container);
    }
  },

  _onSectionUnload: function(evt) {
    var instance = slate.utils.findInstance(this.instances, 'id', evt.detail.sectionId);
  

    if (!instance) {
      return;
    }

    if (typeof instance.onUnload === 'function') {
      instance.onUnload(evt);
    }

    this.instances = slate.utils.removeInstance(this.instances, 'id', evt.detail.sectionId);
  },

  _onSelect: function(evt) {
    var instance = slate.utils.findInstance(this.instances, 'id', evt.detail.sectionId);

    if (instance && typeof instance.onSelect === 'function') {
      instance.onSelect(evt);
    }
  },

  _onDeselect: function(evt) {
    var instance = slate.utils.findInstance(this.instances, 'id', evt.detail.sectionId);

    if (instance && typeof instance.onDeselect === 'function') {
      instance.onDeselect(evt);
    }
  },

  _onReorder: function(evt) {
    var instance = slate.utils.findInstance(this.instances, 'id', evt.detail.sectionId);

    if (instance && typeof instance.onReorder === 'function') {
      instance.onReorder(evt);
    }
  },

  _onBlockSelect: function(evt) {
    var instance = slate.utils.findInstance(this.instances, 'id', evt.detail.sectionId);

    if (instance && typeof instance.onBlockSelect === 'function') {
      instance.onBlockSelect(evt);
    }
  },

  _onBlockDeselect: function(evt) {
    var instance = slate.utils.findInstance(this.instances, 'id', evt.detail.sectionId);

    if (instance && typeof instance.onBlockDeselect === 'function') {
      instance.onBlockDeselect(evt);
    }
  },

  register: function(type, constructor) {
    this.constructors[type] = constructor;

    $('[data-section-type=' + type + ']').each(function(index, container) {
      this._createInstance(container, constructor);
    }.bind(this));
  }
});

/**
 * Currency Helpers
 * -----------------------------------------------------------------------------
 * A collection of useful functions that help with currency formatting
 *
 * Current contents
 * - formatMoney - Takes an amount in cents and returns it as a formatted dollar value.
 *
 */

slate.Currency = (function () {
  var moneyFormat = '${{amount}}';

  /**
   * Format money values based on your shop currency settings
   * @param  {Number|string} cents - value in cents or dollar amount e.g. 300 cents
   * or 3.00 dollars
   * @param  {String} format - shop money_format setting
   * @return {String} value - formatted value
   */
  function formatMoney(cents, format) {
    if (typeof cents === 'string') {
      cents = cents.replace('.', '');
    }
    var value = '';
    var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    var formatString = (format || moneyFormat);

    function formatWithDelimiters(number, precision, thousands, decimal) {
      precision = slate.utils.defaultTo(precision, 2);
      thousands = slate.utils.defaultTo(thousands, ',');
      decimal = slate.utils.defaultTo(decimal, '.');

      if (isNaN(number) || number == null) {
        return 0;
      }

      number = (number / 100.0).toFixed(precision);

      var parts = number.split('.');
      var dollarsAmount = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands);
      var centsAmount = parts[1] ? (decimal + parts[1]) : '';

      return dollarsAmount + centsAmount;
    }

    switch (formatString.match(placeholderRegex)[1]) {
      case 'amount':
        value = formatWithDelimiters(cents, 2);
        break;
      case 'amount_no_decimals':
        value = formatWithDelimiters(cents, 0);
        break;
      case 'amount_with_comma_separator':
        value = formatWithDelimiters(cents, 2, '.', ',');
        break;
      case 'amount_no_decimals_with_comma_separator':
        value = formatWithDelimiters(cents, 0, '.', ',');
        break;
    }

    return formatString.replace(placeholderRegex, value);
  }

  return {
    formatMoney: formatMoney
  };
})();

/**
 * Image Helper Functions
 * -----------------------------------------------------------------------------
 * A collection of functions that help with basic image operations.
 *
 */

slate.Image = (function() {

  /**
   * Preloads an image in memory and uses the browsers cache to store it until needed.
   *
   * @param {Array} images - A list of image urls
   * @param {String} size - A shopify image size attribute
   */

  function preload(images, size) {
    if (typeof images === 'string') {
      images = [images];
    }

    for (var i = 0; i < images.length; i++) {
      var image = images[i];
      this.loadImage(this.getSizedImageUrl(image, size));
    }
  }

  /**
   * Loads and caches an image in the browsers cache.
   * @param {string} path - An image url
   */
  function loadImage(path) {
    new Image().src = path;
  }

  /**
   * Find the Shopify image attribute size
   *
   * @param {string} src
   * @returns {null}
   */
  function imageSize(src) {
    var match = src.match(/.+_((?:pico|icon|thumb|small|compact|medium|large|grande)|\d{1,4}x\d{0,4}|x\d{1,4})[_\.@]/);

    if (match) {
      return match[1];
    } else {
      return null;
    }
  }

  /**
   * Adds a Shopify size attribute to a URL
   *
   * @param src
   * @param size
   * @returns {*}
   */
  function getSizedImageUrl(src, size) {
    if (size === null) {
      return src;
    }

    if (size === 'master') {
      return this.removeProtocol(src);
    }

    var match = src.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i);

    if (match) {
      var prefix = src.split(match[0]);
      var suffix = match[0];

      return this.removeProtocol(prefix[0] + '_' + size + suffix);
    } else {
      return null;
    }
  }

  function removeProtocol(path) {
    return path.replace(/http(s)?:/, '');
  }

  return {
    preload: preload,
    loadImage: loadImage,
    imageSize: imageSize,
    getSizedImageUrl: getSizedImageUrl,
    removeProtocol: removeProtocol
  };
})();

/**
 * Variant Selection scripts
 * ------------------------------------------------------------------------------
 *
 * Handles change events from the variant inputs in any `cart/add` forms that may
 * exist. Also updates the master select and triggers updates when the variants
 * price or image changes.
 *
 * @namespace variants
 */

slate.Variants = (function() {

  /**
   * Variant constructor
   *
   * @param {object} options - Settings from `product.js`
   */
  function Variants(options) {
    this.$container = options.$container;
    this.product = options.product;
    this.singleOptionSelector = options.singleOptionSelector;
    this.originalSelectorId = options.originalSelectorId;
    this.enableHistoryState = options.enableHistoryState;
    this.currentVariant = this._getVariantFromOptions();

    $(this.singleOptionSelector, this.$container).on('change', this._onSelectChange.bind(this));
  }

  Variants.prototype = $.extend({}, Variants.prototype, {

    /**
     * Get the currently selected options from add-to-cart form. Works with all
     * form input elements.
     *
     * @return {array} options - Values of currently selected variants
     */
    _getCurrentOptions: function() {
      var currentOptions = $.map($(this.singleOptionSelector, this.$container), function(element) {
        var $element = $(element);
        var type = $element.attr('type');
        var currentOption = {};

        if (type === 'radio' || type === 'checkbox') {
          if ($element[0].checked) {
            currentOption.value = $element.val();
            currentOption.index = $element.data('index');

            return currentOption;
          } else {
            return false;
          }
        } else {
          currentOption.value = $element.val();
          currentOption.index = $element.data('index');

          return currentOption;
        }
      });

      // remove any unchecked input values if using radio buttons or checkboxes
      currentOptions = slate.utils.compact(currentOptions);

      return currentOptions;
    },

    /**
     * Find variant based on selected values.
     *
     * @param  {array} selectedValues - Values of variant inputs
     * @return {object || undefined} found - Variant object from product.variants
     */
    _getVariantFromOptions: function() {
      var selectedValues = this._getCurrentOptions();
      var variants = this.product.variants;
      var found = false;

      variants.forEach(function(variant) {
        var satisfied = true;

        selectedValues.forEach(function(option) {
          if (satisfied) {
            satisfied = (option.value === variant[option.index]);
          }
        });

        if (satisfied) {
          found = variant;
        }
      });

      return found || null;
    },

    /**
     * Event handler for when a variant input changes.
     */
    _onSelectChange: function() {
      var variant = this._getVariantFromOptions();

      this.$container.trigger({
        type: 'variantChange',
        variant: variant
      });

      if (!variant) {
        return;
      }

      this._updateMasterSelect(variant);
      this._updateImages(variant);
      this._updatePrice(variant);
      this.currentVariant = variant;

      if (this.enableHistoryState) {
        this._updateHistoryState(variant);
      }
    },

    /**
     * Trigger event when variant image changes
     *
     * @param  {object} variant - Currently selected variant
     * @return {event}  variantImageChange
     */
    _updateImages: function(variant) {
      var variantImage = variant.featured_image || {};
      var currentVariantImage = this.currentVariant.featured_image || {};

      if (!variant.featured_image || variantImage.src === currentVariantImage.src) {
        return;
      }

      this.$container.trigger({
        type: 'variantImageChange',
        variant: variant
      });
    },

    /**
     * Trigger event when variant price changes.
     *
     * @param  {object} variant - Currently selected variant
     * @return {event} variantPriceChange
     */
    _updatePrice: function(variant) {
//       if (variant.price === this.currentVariant.price && variant.compare_at_price === this.currentVariant.compare_at_price) {
//         return;
//       }

      this.$container.trigger({
        type: 'variantPriceChange',
        variant: variant
      });
    },

    /**
     * Update history state for product deeplinking
     *
     * @param {object} variant - Currently selected variant
     */
    _updateHistoryState: function(variant) {
      if (!history.replaceState || !variant) {
        return;
      }

      var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?variant=' + variant.id;
      window.history.replaceState({path: newurl}, '', newurl);
    },

    /**
     * Update hidden master select of variant change
     *
     * @param {object} variant - Currently selected variant
     */
    _updateMasterSelect: function(variant) {
      $(this.originalSelectorId, this.$container)[0].value = variant.id;
    }
  });

  return Variants;
})();


/*================ Sections ================*/
/**
 * Product Template Script
 * ------------------------------------------------------------------------------
 * A file that contains scripts highly couple code to the Product template.
 *
   * @namespace product
 */
theme.Product = (function() {

  var selectors = {
    addToCart: '[data-add-to-cart]',
    addToCartText: '[data-add-to-cart-text]',
    comparePrice: '[data-compare-price]',
    comparePriceText: '[data-compare-text]',
    originalSelectorId: '[data-product-select]',
    priceWrapper: '[data-price-wrapper]',
    productFeaturedImage: '[data-product-featured-image]',
    productJson: '[data-product-json]',
    productPrice: '[data-product-price]',
    productThumbs: '[data-product-single-thumbnail]',
    singleOptionSelector: '[data-single-option-selector]'
  };

  /**
   * Product section constructor. Runs on page load as well as Theme Editor
   * `section:load` events.
   * @param {string} container - selector for the section container DOM element
   */
  function Product(container) {
    this.$container = $(container);

    // Stop parsing if we don't have the product json script tag when loading
    // section in the Theme Editor
    if (!$(selectors.productJson, this.$container).html()) {
      return;
    }

    var sectionId = this.$container.attr('data-section-id');
    this.productSingleObject = JSON.parse($(selectors.productJson, this.$container).html());

    var options = {
      $container: this.$container,
      enableHistoryState: this.$container.data('enable-history-state') || false,
      singleOptionSelector: selectors.singleOptionSelector,
      originalSelectorId: selectors.originalSelectorId,
      product: this.productSingleObject
    };

    this.settings = {};
    this.namespace = '.product';
    this.variants = new slate.Variants(options);
    this.$featuredImage = $(selectors.productFeaturedImage, this.$container);

    this.$container.on('variantChange' + this.namespace, this.updateAddToCartState.bind(this));
    this.$container.on('variantPriceChange' + this.namespace, this.updateProductPrices.bind(this));

    if (this.$featuredImage.length > 0) {
      this.settings.imageSize = slate.Image.imageSize(this.$featuredImage.attr('src'));
      slate.Image.preload(this.productSingleObject.images, this.settings.imageSize);

      this.$container.on('variantImageChange' + this.namespace, this.updateProductImage.bind(this));
    }
  }

  Product.prototype = $.extend({}, Product.prototype, {

    /**
     * Updates the DOM state of the add to cart button
     *
     * @param {boolean} enabled - Decides whether cart is enabled or disabled
     * @param {string} text - Updates the text notification content of the cart
     */
    updateAddToCartState: function(evt) {
      var variant = evt.variant;

      if (variant) {
        $(selectors.priceWrapper, this.$container).removeClass('hide');
      } else {
        $(selectors.addToCart, this.$container).prop('disabled', true);
        $(selectors.addToCartText, this.$container).html(theme.strings.unavailable);
        $(selectors.priceWrapper, this.$container).addClass('hide');
        return;
      }

      if (variant.available) {
        $(selectors.addToCart, this.$container).prop('disabled', false);
    	$(selectors.addToCartText, this.$container).html(theme.strings.addToCart);
      } else {
        $(selectors.addToCart, this.$container).prop('disabled', true);
        $(selectors.addToCartText, this.$container).html(theme.strings.soldOut);
      }
    },

    /**
     * Updates the DOM with specified prices
     *
     * @param {string} productPrice - The current price of the product
     * @param {string} comparePrice - The original price of the product
     */
    updateProductPrices: function(evt) {
      var variant = evt.variant;
      var $comparePrice = $(selectors.comparePrice, this.$container);
      var $compareEls = $comparePrice.add(selectors.comparePriceText, this.$container);

      $(selectors.productPrice, this.$container)
      .html(slate.Currency.formatMoney(variant.price, theme.moneyFormat));

      if (variant.compare_at_price > variant.price) {
        $comparePrice.html(slate.Currency.formatMoney(variant.compare_at_price, theme.moneyFormat));
        $compareEls.removeClass('hide');
      } else {
        $comparePrice.html('');
        $compareEls.addClass('hide');
      }
    },

    /**
     * Updates the DOM with the specified image URL
     *
     * @param {string} src - Image src URL
     */
    updateProductImage: function(evt) {
      var variant = evt.variant;
      var sizedImgUrl = slate.Image.getSizedImageUrl(variant.featured_image.src, this.settings.imageSize);

      this.$featuredImage.attr('src', sizedImgUrl);
    },

    /**
     * Event callback for Theme Editor `section:unload` event
     */
    onUnload: function() {
      this.$container.off(this.namespace);
    }
  });

  return Product;
})();


/*================ Templates ================*/
/**
 * Customer Addresses Script
 * ------------------------------------------------------------------------------
 * A file that contains scripts highly couple code to the Customer Addresses
 * template.
 *
 * @namespace customerAddresses
 */

theme.customerAddresses = (function() {
  var $newAddressForm = $('#AddressNewForm');

  if (!$newAddressForm.length) {
    return;
  }

  // Initialize observers on address selectors, defined in shopify_common.js
  if (Shopify) {
    new Shopify.CountryProvinceSelector('AddressCountryNew', 'AddressProvinceNew', {
      hideElement: 'AddressProvinceContainerNew'
    });
  }

  // Initialize each edit form's country/province selector
  $('.address-country-option').each(function() {
    var formId = $(this).data('form-id');
    var countrySelector = 'AddressCountry_' + formId;
    var provinceSelector = 'AddressProvince_' + formId;
    var containerSelector = 'AddressProvinceContainer_' + formId;

    new Shopify.CountryProvinceSelector(countrySelector, provinceSelector, {
      hideElement: containerSelector
    });
  });

  // Toggle new/edit address forms
  $('.address-new-toggle').on('click', function() {
    $newAddressForm.toggleClass('hide');
  });

  $('.address-edit-toggle').on('click', function() {
    var formId = $(this).data('form-id');
    $('#EditAddress_' + formId).toggleClass('hide');
  });

  $('.address-delete').on('click', function() {
    var $el = $(this);
    var formId = $el.data('form-id');
    var confirmMessage = $el.data('confirm-message');
    if (confirm(confirmMessage || 'Are you sure you wish to delete this address?')) {
      Shopify.postLink('/account/addresses/' + formId, {parameters: {_method: 'delete'}});
    }
  });
})();

/**
 * Password Template Script
 * ------------------------------------------------------------------------------
 * A file that contains scripts highly couple code to the Password template.
 *
 * @namespace password
 */

theme.customerLogin = (function() { 
  var config = {
    recoverPasswordForm: '#RecoverPassword',
    hideRecoverPasswordLink: '#HideRecoverPasswordLink'
  };

  if (!$(config.recoverPasswordForm).length) {
    return;
  }

  checkUrlHash();
  resetPasswordSuccess();

  $(config.recoverPasswordForm).on('click', onShowHidePasswordForm);
  $(config.hideRecoverPasswordLink).on('click', onShowHidePasswordForm);

  function onShowHidePasswordForm(evt) {
    evt.preventDefault();
    toggleRecoverPasswordForm();
  }

  function checkUrlHash() {
    var hash = window.location.hash;

    // Allow deep linking to recover password form
    if (hash === '#recover') {
      toggleRecoverPasswordForm();
    }
  }

  /**
   *  Show/Hide recover password form
   */
  function toggleRecoverPasswordForm() {
    $('#RecoverPasswordForm').toggleClass('hide');
    $('#CustomerLoginForm').toggleClass('hide');
    $('.create_account').toggleClass('hide');
  }

  /**
   *  Show reset password success message
   */
  function resetPasswordSuccess() {
    var $formState = $('.reset-password-success');

    // check if reset password form was successfully submited.
    if (!$formState.length) {
      return;
    }

    // show success message
    $('#ResetSuccess').removeClass('hide');
  }
})();

/* ================================ COLLECTION PAGE FILTER ===================================*/
var cart_money_format="${{amount}}"; 
var money_format_update="<span class='money'>${{amount}}</span>";
var quickShop_money_format="<span class='money'>"+"${{amount}}"+"</span>";

//var quickShopModal = $('#quick-shop-modal');
var quickShopContainer = $('#quick-shop-container');
var quickShopImage = $('#quick-shop-image');
var quickShopRating = $('#quick-shop-container .rating-star');
var quickShopDescription = $('#quick-shop-description');
var quickShopVariantsContainer = $('#quick-shop-variants-container');
var quickShopComboProducts = $('#quick-shop-combo-products');
var quickShopComboProductsLoading = $('#quick-shop-combo-products-loading');
var quickShopAddToCartButton = $('#quick-shop-add');
var quickShopTags = $('#quick-shop-tags');
var quickShopProductActions = $('#quick-shop-product-actions');
var quickShopModalBackground = $('#quick-shop-modal .quick-shop-modal-bg');
var quickShopJson = $('#quick_json');
var quickShopStock = $('#quick-shop-container .stock');
var quickShopSku = $('#quick-shop-container .sku_no');
var quickShopSwatchElement = $('#quick-swatch-element');
var quickShopShareFB = $('#quick-share-fb');
var quickShopShareTwitter = $('#quick-share-twitter');
var quickShopSharePinterest = $('#quick-share-pinterest');
var quickShopViewMore = $('#quick-shop-container .quick-view-more');
var quickShopWishlist = $('#quick-shop-container .action-list .quick-product-wishlist');
var quickShopWishlistLogin = $('#quick-shop-container .action-list .quick-product-wishlist-login');


Shopify.queryParams = {};
if (location.search.length) {
  for (var aKeyValue, i = 0, aCouples = location.search.substr(1).split("&"); i < aCouples.length; i++) {
    aKeyValue = aCouples[i].split("=");
    if (aKeyValue.length > 1) {
      Shopify.queryParams[decodeURIComponent(aKeyValue[0])] = decodeURIComponent(aKeyValue[1])
    }
  }
}
Shopify.optionsMap = {};
Shopify.updateOptionsInSelector = function(selectorIndex) {

  switch (selectorIndex) {
    case 0:
      var key = 'root';
      var selector = jQuery('.single-option-selector:eq(0)');
      break;
    case 1:
      var key = jQuery('.single-option-selector:eq(0)').val();
      var selector = jQuery('.single-option-selector:eq(1)');
      break;
    case 2:
      var key = jQuery('.single-option-selector:eq(0)').val();  
      key += ' / ' + jQuery('.single-option-selector:eq(1)').val();
      var selector = jQuery('.single-option-selector:eq(2)');
  }

  var initialValue = selector.val();
  selector.empty();    
  var availableOptions = Shopify.optionsMap[key];
  for (var i=0; i<availableOptions.length; i++) {
    var option = availableOptions[i];
    var newOption = jQuery('<option></option>').val(option).html(option);
    selector.append(newOption);
  }
  jQuery('.swatch[data-option-index="' + selectorIndex + '"] .swatch-element').each(function() {
    if (jQuery.inArray($(this).attr('data-value'), availableOptions) !== -1) {
      $(this).removeClass('soldout').show().find(':radio').removeAttr('disabled','disabled').removeAttr('checked');
    }
    else {
      $(this).addClass('soldout').hide().find(':radio').removeAttr('checked').attr('disabled','disabled');
    }
  });
  if (jQuery.inArray(initialValue, availableOptions) !== -1) {
    selector.val(initialValue);
  }
  selector.trigger('change');
};
Shopify.linkOptionSelectors = function(product) {
  // Building our mapping object.  
  for (var i=0; i<product.variants.length; i++) {
    var variant = product.variants[i];
    if (variant.available) {
      // Gathering values for the 1st drop-down.
      Shopify.optionsMap['root'] = Shopify.optionsMap['root'] || [];
      Shopify.optionsMap['root'].push(variant.option1);
      Shopify.optionsMap['root'] = Shopify.uniq(Shopify.optionsMap['root']);
      // Gathering values for the 2nd drop-down.
      if (product.options.length > 1) {
        var key = variant.option1;
        Shopify.optionsMap[key] = Shopify.optionsMap[key] || [];
        Shopify.optionsMap[key].push(variant.option2);
        Shopify.optionsMap[key] = Shopify.uniq(Shopify.optionsMap[key]);
      }
      // Gathering values for the 3rd drop-down.
      if (product.options.length === 3) {
        var key = variant.option1 + ' / ' + variant.option2;
        Shopify.optionsMap[key] = Shopify.optionsMap[key] || [];
        Shopify.optionsMap[key].push(variant.option3);
        Shopify.optionsMap[key] = Shopify.uniq(Shopify.optionsMap[key]);
      }
    }
  }
  // Update options right away.
  Shopify.updateOptionsInSelector(0);
  if (product.options.length > 1) Shopify.updateOptionsInSelector(1);
  if (product.options.length === 3) Shopify.updateOptionsInSelector(2);
  // When there is an update in the first dropdown.
  jQuery(".single-option-selector:eq(0)").change(function() {
    Shopify.updateOptionsInSelector(1);
    if (product.options.length === 3) Shopify.updateOptionsInSelector(2);
    return true;
  });
  // When there is an update in the second dropdown.
  jQuery(".single-option-selector:eq(1)").change(function() {
    if (product.options.length === 3) Shopify.updateOptionsInSelector(2);
    return true;
  });  
};

var shopify_coll_filter='';
var SW = SW || {};
!function($) {
  var pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1
  , $window = $(window)
  , body = $("body")
  , deviceAgent = navigator.userAgent.toLowerCase()
  , isMobileAlt = deviceAgent.match(/(iphone|ipod|ipad|android|iemobile)/)
  , imageZoomThreshold = 0
  , loading = !1
  , infinite_loaded_count = 1;
  SW.header = {
    init: function() {

    }
  }, 
    SW.collection = {
    init: function() {      
      SW.collection.layerFilterInit()      
    },      
    createCookie: function(a, b, c) {
      var d;
      if (c) {
        var e = new Date;
        e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3),
          d = "; expires=" + e.toGMTString()
      } else
        d = "";
      document.cookie = escape(a) + "=" + escape(b) + d + "; path=/"
    },
    readCookie: function(a) {
      for (var b = escape(a) + "=", c = document.cookie.split(";"), d = 0; d < c.length; d++) {
        for (var e = c[d]; " " === e.charAt(0); )
          e = e.substring(1, e.length);
        if (0 === e.indexOf(b))
          return unescape(e.substring(b.length, e.length))
          }
      return null
    },
    eraseCookie: function(a) {
      SW.collection.createCookie(a, "", -1)
    },
    translateBlock: function(a) {
      multi_language && translator.isLang2() && translator.doTranslate(a)
    },
    translateText: function(a) {
      if (!multi_language || a.indexOf("|") < 0)
        return a;
      if (multi_language) {
        var b = a.split("|");
        return translator.isLang2() ? b[1] : b[0]
      }
    },   
    productDealInit: function(a) {
      var b = a.data("date");
      if (b) {
        var c = {
          date: b
        };
        $.extend(c, countdown),
          $.extend(c, countdownConfig),
          countdownTemplate && (c.template = countdownTemplate),
          a.countdown(c)
      }
    },    
    showquickmodal: function() {
      var modal =  $('#modalone');
      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("close_popup")[0];
      // When the user clicks on <span> (x), close the modal
      span.onclick = function() {        
        modal.hide().parents('html').removeClass('model-open');
      }

      // When the user clicks anywhere outside of the modal, close it
      $(window).click(function(event){
        if (event.target.id == 'modalone') {          
          modal.hide().parents('html').removeClass('model-open');          
        }
      })
      modal.show().parents('html').addClass('model-open');      
      SW.collection.countDowntInit();

      var sync1 = $("#q_sync1");
      var sync2 = $("#q_sync2");
      var syncedSecondary = true;
      var slidesPerPage = 4;

      sync1.owlCarousel({
        items: 1,
        dots: false
      }).on('changed.owl.carousel', syncPosition);
      sync2.on('initialized.owl.carousel', function () {
        sync2.find(".owl-item").eq(0).addClass("current");
      })
      .owlCarousel({
        items: slidesPerPage,
        dots: false,
        margin: 12,
        nav: true,
        smartSpeed: 200,
        mouseDrag: false,
        touchDrag: false,
        slideSpeed: 500,
        slideBy: slidesPerPage,
        responsiveRefreshRate: 100
      }).on('changed.owl.carousel', syncPosition2);

      function syncPosition(el) {
        var count = el.item.count - 1;
        var current = Math.round(el.item.index - (el.item.count / 2) - .5);
        if (current < 0) {
          current = count;
        }
        if (current > count) {
          current = 0;
        }
        sync2
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");
        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        if (current > end) {
          sync2.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
          sync2.data('owl.carousel').to(current - onscreen, 100, true);
        }
      }
      function syncPosition2(el) {
        if (syncedSecondary) {
          var number = el.item.index;
          sync1.data('owl.carousel').to(number, 100, true);
        }
      }
      sync2.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
      });

    },
    quickshopInit: function() {     
      //$("#product-form").trigger("initproduct"),
      $(document).on("click", ".quick_shop", function() {   debugger;     
        var $this = $(this);
        if($this.length > 0)
        {
          var product_json = $this.parents(".product_page").find('.product-json').html();
          var selectedProduct = JSON.parse(product_json);
          var selectedProductID = selectedProduct.id;          
          quickShopContainer.attr("data-pid",selectedProductID);
          quickShopContainer.attr("data-section-id","");

          quickShopComboProductsLoading.hide();


          // Update add button and Quantity
          quickShopAddToCartButton.data('product-id', selectedProductID);
          $('#Quantity').val(1);

          // Update image
          quickShopImage.empty();
          quickShopImage.html('<div class="pro_img_main slider" id="pro_slider1">');

          quickShopImage.html('<div id="q_sync1" class="owl-carousel owl-theme"></div><div id="q_sync2" class="owl-carousel owl-theme"></div>')
 
          var qs_images = selectedProduct.images;
          $.each(qs_images, function(index, value) {
            if(index)
            {
              quickShopImage.find('#q_sync1').append('<div class="item"><div class="product_img"><img src="'+ Shopify.resizeImage(value, "X550") +'" alt="Product Image" data-product-featured-image class="vertical_middle img-fluid" /></div></div>');
              quickShopImage.find('#q_sync2').append('<div class="item"><div class="product_img"><img src="'+ Shopify.resizeImage(value, "150x150") +'" alt="Product Image" class="vertical_middle img-fluid" /></div></div>');
            }
          });

          if(selectedProduct.featured_image)
          {
            quickShopImage.find('#q_sync1').append('<div class="item"><div class="product_img"><img src="'+ Shopify.resizeImage(selectedProduct.featured_image, "X550") +'" alt="Product Image" data-product-featured-image class="vertical_middle img-fluid" /></div></div>');
            quickShopImage.find('#q_sync2').append('<div class="item"><div class="product_img"><img src="'+ Shopify.resizeImage(selectedProduct.featured_image, "150x150") +'" alt="Product Image" class="vertical_middle img-fluid" /></div></div>');
          }

          // Update Rating Review
          quickShopRating.empty();
          quickShopRating.append("<span class=\"shopify-product-reviews-badge\" data-id=\""+selectedProductID+"\"></span>");

          $.getScript(window.location.protocol + "//productreviews.shopifycdn.com/assets/v4/spr.js");

          // Set SKU
          quickShopSku.html('SKU: '+'-');

          // selected an invalid or out of stock variant
          if(selectedProduct.available)
          {
            quickShopStock.addClass('in_stock').html(window.inventory_text.in_stock);  
          }
          quickShopContainer.find('#prdprice').html(convertCurrency(selectedProduct.price));          

          // Share on Social Media
          if(quickShopShareFB.length > 0)
          {
            quickShopShareFB.attr('href','//www.facebook.com/sharer.php?u=https://'+Shopify.shop+'/products/'+selectedProduct.handle);
          }
          if(quickShopShareTwitter.length > 0)
          {
            quickShopShareTwitter.attr('href','//twitter.com/share?text='+ selectedProduct.title +'&url=https://'+Shopify.shop+'/products/'+selectedProduct.handle);
          }
          if(quickShopSharePinterest.length > 0)
          {
            quickShopSharePinterest.attr('href','//pinterest.com/pin/create/button/?url=https://'+Shopify.shop+'/products/'+selectedProduct.handle+'&media='+Shopify.resizeImage(selectedProduct.featured_image, "1024x1024")+'&description='+selectedProduct.title);
          }

          //Set View more link
          quickShopViewMore.attr('href','https://'+Shopify.shop+ $(this).parents('.featured_img_content').siblings('.featured_detail_content').find('.product-view-title').attr('href'));


          //Set attributes for the variant change trigger
          quickShopJson.html('');       
          quickShopJson.append('<script type="application/json" id="ProductJson--'+selectedProductID+'"></script>');
          quickShopJson.find('#ProductJson--'+selectedProductID).append(product_json);

          // Generate variants
          var productVariants = selectedProduct.variants;
          var productVariantsCount = productVariants.length;

          quickShopVariantsContainer.html('');
          quickShopSwatchElement.html('');
          quickShopAddToCartButton.removeAttr('disabled').fadeTo(200,1);
          quickShopAddToCartButton.children('span').text(theme.strings.addToCart);



          if (productVariantsCount > 1) {          
            // Reveal variants container
            quickShopVariantsContainer.show();

            // Build variants element
            var quickShopVariantElement = $('<select>',{ 'id': ('#quick-shop-variants-' + selectedProductID) , 'name': 'id'});
            var quickShopVariantOptions = '';

            for (var i=0; i < productVariantsCount; i++) {
              quickShopVariantOptions += '<option value="'+ productVariants[i].id +'">'+ productVariants[i].title +'</option>'
            };

            // Add variants element to page
            quickShopVariantElement.append(quickShopVariantOptions);
            quickShopVariantsContainer.append(quickShopVariantElement);

            // Bind variants to OptionSelect JS
            new Shopify.OptionSelectors(('#quick-shop-variants-' + selectedProductID), { product: selectedProduct, onVariantSelected: selectOptionCallback  });

            // Add label if only one product option and it isn't 'Title'.
            if (selectedProduct.options.length == 1 && selectedProduct.options[0] != 'Title' ){
              $('#quick-shop-product-actions .selector-wrapper:eq(0)').prepend('<label>'+ selectedProduct.options[0] +'</label>');
            }

            // check for the color swatch And add 'Pick an option'

            var coloroptionname ='',found_color_option_index = -1,productOptions = [];             
            for (var i=0; i < selectedProduct.options.length; i++) {
              if(selectedProduct.options[i].toLowerCase() == 'color' || selectedProduct.options[i].toLowerCase() == 'colour')
              {
                found_color_option_index = i;
                coloroptionname = selectedProduct.options[i].toLowerCase();
              }              
            }

            //swatch
            if(quickShopSwatchElement.length > 0)
            {             
              if(found_color_option_index != -1)
              {
                var colors="";
                quickShopSwatchElement.html('<label class="title_h5 text-capitalize">Color</label>');
                var form = quickShopContainer.find('form.variants');   

                for (var i=0,length=selectedProduct.variants.length; i<length; i++) {              

                  var variant = selectedProduct.variants[i];
                  var value = variant.options[found_color_option_index];
                  var split_str = colors.split(",");
                  if(split_str.indexOf(value) == -1)
                  {
                    colors += value.replace(' ','_') + ",";
                    // Bind in div
                    quickShopSwatchElement.append('<div data-value="'+value.replace(' ','_')+'" class="radio text-uppercase text-center color '+value.replace(' ','_')+'"></div>');                    
                    quickShopSwatchElement.find('.'+value.replace(' ','_')).append('<input id="quick-swatch-'+selectedProductID+'-'+value+'" type="radio" class="quick_swatch_select" name="'+found_color_option_index+'-'+selectedProductID+'" value="'+value+'" '+ (selectedProduct.variants[i].available ? '':'disabled') + (0 == i ? 'checked' : '' ) + '/>');                    
                    var assestsurl = asset_url.replace('/?',value.toLowerCase().replace(' ','_')+'.png?')
                    quickShopSwatchElement.find('.'+value.replace(' ','_')).append('<label for="quick-swatch-'+selectedProductID+'-'+value+'" style="background-color: '+ value.toLowerCase().replace(' ','') +'; background-image: url('+ assestsurl +')"><span class="crossed-out"></span></label>');

                  }
                }
                quickShopSwatchElement.css('display','block');
                quickShopVariantsContainer.find('.selector-wrapper.'+coloroptionname).css('display','none');   

                quickShopSwatchElement.find(".quick_swatch_select:radio").change(function(e) {             
                  var color = $(this).val();    
                  quickShopVariantsContainer.find('.selector-wrapper.'+coloroptionname).find('select').val(color);
                  quickShopVariantsContainer.find('.selector-wrapper.'+coloroptionname).find('select').change();
                });
              }
            }
          } else { // If product only has a single variant    
            // Hide unnecessary variants container
            quickShopVariantsContainer.hide(); 

            // Build variants element
            var quickShopVariantElement = $('<select>',{ 'id': ('#quick-shop-variants-' + selectedProductID) , 'name': 'id','class':'form-control'});
            var quickShopVariantOptions = '';

            for(var i=0; i < productVariantsCount; i++){
              quickShopVariantOptions += '<option value="'+ productVariants[i].id +'">'+ productVariants[i].title +'</option>'
            };

            // Add variants element to page
            quickShopVariantElement.append(quickShopVariantOptions);
            quickShopVariantsContainer.append(quickShopVariantElement);  

            // Add tags
            quickShopTags.html('translation missing: en.products.general.tag'+ selectedProduct.tags);

            // Update the add button to include correct variant id
            quickShopAddToCartButton.data('variant-id', productVariants[0].id);

          } // END of (productVariantsCount > 1)

          //Wishlist                                                             
          var ctag = '';
          0 < quickShopWishlist.length ? ctag = $('#C_value').val() : '';
          var _Ctagsplit = ctag.split(','), _checktag = '',check = 0;

          $.each( _Ctagsplit, function(key, value ) {
            if(value.indexOf(selectedProductID) >= 0)
              _checktag = 'x' + value;
          });

          check = (_checktag.length - selectedProductID.toString().length) % 2;
          0 == check ? 
            quickShopWishlist
          .addClass('add-to-wishlist')                                                            
          .attr('href','#')
          .data('tags',selectedProductID)
          .find('span').text(window.wishlist_text.add_to_wishlist) :
          quickShopWishlist
          .removeClass('add-to-wishlist')                                                            
          .find('span').text(window.wishlist_text.added_in_wishlist);


          0 == check ? quickShopWishlist.parents('ul').removeClass('added-wishlist') : quickShopWishlist.parents('ul').addClass('added-wishlist')


          0 == check ? addToWishlist() : '';
          // SW.productMediaManager.wireThumbnails();
          SW.collection.showquickmodal();
          //SW.productMediaManager.createZoom($(".gallery-image.visible"));
        }
      })
    },
    convertToSlug: function(a) {
      return a.toLowerCase().replace(/[^a-z0-9 -]/g, "").replace(/\s+/g, "-").replace(/-+/g, "-")
    },
    sortbyFilter: function() {
      $(document).on("change", ".sort-by .field", function(a) {
        a.preventDefault();
        var b = $(this)
        , c = b.val();
        Shopify.queryParams.sort_by = c,
          SW.collection.filterAjaxRequest();    
      })
    },
    limitedAsFilter: function() {
      $(document).on("change", ".limited-view .field", function(a) {
        a.preventDefault();
        var b = $(this)
        , c = b.val();
        Shopify.queryParams.view = c,
          SW.collection.filterAjaxRequest();        
      })
    },            
    swatchListFilter: function() { 
      $(document).on("click", ".narrow-by-list .swatch-list .item:not(.disable)", function(event) 
                     {    
        event.preventDefault();        
        var a = $(this),dv = $.trim($( "div[name*='dvshopping']" ).html()),p = '',str=''
        , b = a.find("input").val()
        , p = a.find("input").data('parent').replace(" ", "_")
        ,  p1 = a.find("input").data('parent').replace(" ", "_")
        , copy = a.find("input").data('parent')
        , c = [];
                      str.replace("Microsoft", "W3Schools")
        if (Shopify.queryParams.constraint && (c = Shopify.queryParams.constraint.split("+")),
            !a.hasClass("active")) {
          var d = a.parents(".layer-filter, .advanced-filter").find(".active");
          d.length > 0 && d.each(function() {
            var a = $(this).data("handle");
            if ($(this).removeClass("active"),
                a) {
              var b = c.indexOf(a);
              b >= 0 && c.splice(b, 1)
            }            
          })
        }

        if (b) {
          var e = c.indexOf(b);
          0 > e ? (c.push(b),
                   a.addClass("active")) : (c.splice(e, 1),
                                            a.removeClass("active"))
          if(e >= 0)
          {            
            $('#dvshopping .fill_value[name="'+p+'"] p').each(function() {
              if($(this).text() == b)
                $(this).remove();
            });
            if($('.fill_value[name="'+p+'"] p').length <= 0){
              $('#dvshopping .fill_value[name="'+p+'"]').remove();
            }
            if( $('#dvshopping .fill_value[name="'+p+'"]').length <= 0){
              $('#dvshopping div[name="'+p+'"]').parents('.select_category').remove();
            }
          }
          else
          {
            str += "<p><span style='background-color:"+b+";'></span>"+b+"<a class='clearfilter' data-val='"+b+"' href='javascript:void(0)' data-id='"+p+"'><i class='flaticon-close'></i></a></p>";
            if($('#dvshopping .fill_value[name="'+p+'"]').length > 0)
            {
              $('#dvshopping .fill_value[name="'+p+'"]').append(str);
            }
            else
            {
              if(p != '')
                p = "<div name='"+p+"' class='fill_type'><p class='fill_name'>"+p+"</p><a class='clear clear_fil' href='javascript:void(0)' data-id='"+p+"'>Clear</a></div>";
              $( "div[name*='dvshopping']" ).html(dv + '<div class="select_category">'+p+'<div name='+p1+' class="fill_value '+ (copy.indexOf("color") >= 0 ? "fill_color_box" : "" ) +'">'+str+'</div></div>');
            }
          }
          
          if($.trim($('#dvshopping').find('.select_category').html()) == '')
          {
            $('.claerall').css('display','none');
            $("#dvfilter").css("display", "none");
          }
          else
          {
            $('.claerall').css('display','block');
            $("#dvfilter").css("display", "block");
          }
        }
        c.length ? Shopify.queryParams.constraint = c.join("+") : delete Shopify.queryParams.constraint,
          SW.collection.filterAjaxRequest()   
      })
    },      
    layerFilterInit: function() {                
      SW.collection.sortbyFilter(),
        SW.collection.limitedAsFilter(),
        SW.collection.swatchListFilter(),
        SW.collection.layerShoppingby();
    },
    filterCreateUrl: function(a) {
      var b = $.param(Shopify.queryParams).replace(/%2B/g, "+");

      if(shopify_coll_filter != '')
      {
        var rurl = shopify_coll_filter;
        shopify_coll_filter = '';
        window.location.href = a ? "" != b ? a + "?" + b : a : rurl + "?" + b;
      }
      else
      {
        return a ? "" != b ? a + "?" + b : a : location.pathname + "?" + b
      }
    },
    filterAjaxRequest: function(a) {
      delete Shopify.queryParams.page;
      var b = SW.collection.filterCreateUrl(a);
      History.pushState({
        param: Shopify.queryParams
      }, b, b),
        SW.collection.filterGetContent(b)
    },
    filterGetContent: function(a) {
      $.ajax({
        type: "get",
        url: a,
        beforeSend: function() {
          $("#resultLoading").show()
        },
        success: function(b) {                          
          infinite_loaded_count = 0;
          var c = 'Products - Collection';//b.match("<title>(.*?)</title>")[1];          
          $("#collection-main").empty().html($(b).find("#collection-main").html()),
            $(".narrow-by-list").empty().html($(b).find(".narrow-by-list").html()),
            $(".pagination").empty().html($(b).find(".pagination").html()),
            $(".main-breadcrumbs").empty().html($(b).find(".main-breadcrumbs").html()),
            History.pushState({
            param: Shopify.queryParams
          }, c, a),                 
            $("#resultLoading").hide(),
            null != SW.collection.readCookie("products-listmode") && SW.collection.layoutListInit(), 
            SW.collection.countDowntInit(),
            SW.collection.bindMoreFilterTags(),
            SW.collection.showMoreFilterTags()
          //                             $(".spr-badge").length > 0 && (SPR.registerCallbacks(),
          //                                                            SPR.initRatingHandler(),
          //                                                            SPR.initDomEls(),
          //                                                            SPR.loadProducts(),
          //                                                            SPR.loadBadges()),
          //                             frontendData.enableCurrency && currenciesCallbackSpecial(".products-grid span.money")
          if($(window).width() < 991){
            $('.category-products .item-effect').addClass('equalheight');
          }
          equalheight('.equalheight');
          var pro_img = $('.pro_image').outerWidth(); $('.pro_image').css('height',pro_img);
        },
        error: function() {
          $("#resultLoading").hide()
        }
      })


    },
    countDowntInit: function() {
      $(".product-date").each(function(a, b) {
        var c = $(b).attr("data-date")
        , d = {
          yearText: window.date_text.year_text,
          monthText: window.date_text.month_text,
          weekText: window.date_text.week_text,
          dayText: window.date_text.day_text,
          hourText: window.date_text.hour_text,
          minText: window.date_text.min_text,
          secText: window.date_text.sec_text,
          yearSingularText: window.date_text.year_singular_text,
          monthSingularText: window.date_text.month_singular_text,
          weekSingularText: window.date_text.week_singular_text,
          daySingularText: window.date_text.day_singular_text,
          hourSingularText: window.date_text.hour_singular_text,
          minSingularText: window.date_text.min_singular_text,
          secSingularText: window.date_text.sec_singular_text
        }
        , e = '<div class="day"><span class="no">%d</span><span class="text">%td</span></div><div class="hours"><span class="no">%h</span><span class="text">%th</span></div><div class="min"><span class="no">%i</span><span class="text">%ti</span></div><div class="second"><span class="no">%s</span><span class="text">%ts</span></div>';
        if (c) {
          var f = {
            date: c
          };
          $.extend(f, d),
            e && (f.template = e),
            $(b).countdown(f)
        }
      })
    },
    layerShoppingby: function() {
      $(".narrow-by-list .swatch-list").each(function() {
   
        var c = [],copy = '';
           
  Shopify.queryParams.constraint && (c = Shopify.queryParams.constraint.split("+")),   
      a = $(this),dv =$.trim($( "div[name*='dvshopping']" ).html()),p = '',str='';

        a.find("input").each(function() {
          var a = jQuery(this)
          , b = a.val();   
          if (jQuery.inArray(b,c) >= 0) {
            p = a.data('parent'); 
            p1 = a.data('parent');
            copy = a.data('parent');  
            str += "<p><span style='background-color:"+b+";'></span>"+b+"<a class='clearfilter' data-val='"+b+"' href='javascript:void(0)' data-id='"+p+"'><i class='flaticon-close'></i></a></p>";           
          }
        })
        if(p != '')
        {
          p = "<div name='"+p+"' class='fill_type'><p class='fill_name'>"+p+"</p><a class='clear clear_fil' href='javascript:void(0)' data-id='"+p+"'>Clear</a></div>";
        $("div[name='dvshopping']").html(dv+'<div class="select_category">'+p+'<div name='+p1+' class="fill_value '+ (copy.indexOf("color") >= 0 ? "fill_color_box" : "" ) +'">'+str+'</div></div>');
        }
        
        if($.trim($('#dvshopping').find('.select_category').html()) == '')
        {
          $('.claerall').css('display','none');
          $("#dvfilter").css( "display", "none" );
        }
        else
        {
          $('.claerall').css('display','block');
          $("#dvfilter").css("display", "block");
        }
      })
    },
    searchfiltertag: function(a,e,d) { 

      var str = '';
      $("#"+a+" > li:not(.disable)").each(function() {
        var LI = $(this);
        var checkval = LI.find("input").val();
        if (checkval.toLowerCase().indexOf(e) >= 0)        
          str += "<li>"+LI.html()+"</li>";
      });

      if($.trim(str) != '')
        d.html("<ul>"+str+"</ul>");
      else
      {
        if(d.html().indexOf("no_data") < 0)
          d.html("<span class='no_data'>No data</span>");
      }
      SW.collection.countlength(d);
    },
    countlength: function(a){ 
      var num = 8; 
      var line_length =  $(a).find('ul').children().length;        
      var column = parseInt(line_length / num);
      var remaining = parseInt(line_length % num);      
      for(i=0; i<=column; i++){
        if(line_length > 8){
          $(a).find('ul').children('li:lt('+num+')').wrapAll('<div class="collective_list"></div>');
        }
        else{
          $(a).find('ul').children('li:lt('+remaining+')').wrapAll('<div class="collective_list"></div>');
        }
      }
    },
    searchsepratetag: function(t) {  debugger; 
      var e = t.find('input').val();

      $("#"+$(t).siblings("ul").attr('id')+" > li:not(.disable)").each(function() {       
        var LI = $(this);
        $(LI).removeClass('active inactive activemandatory');      

        if($.trim(e) != '')
        {
          var checkval = LI.find("input").val();
          if (checkval.toLowerCase().indexOf(e) >= 0)
          {
            LI.addClass('active');            
          }
          else
          {
            LI.addClass('inactive');           
          }
        } 
      });

      if($.trim(e) != '')
      { 
        if($("#"+$(t).siblings("ul").attr('id')+" li.active").size() <= 0)
        {
          if($("#"+$(t).siblings("ul").attr('id')).html().indexOf("prepended") < 0)
            $("#"+$(t).siblings("ul").attr('id')).append('<span class="prepended">No Data</span');        
        }
        else
        {
          $("#"+$(t).siblings("ul").attr('id')+" .prepended").remove();        
        }

        //Bind data based on search
        size_li = $("#"+$(t).siblings("ul").attr('id')+" li.active").size();
        x=5;
        $('#' +$(t).siblings("ul").attr('id')+' li.active:lt('+(size_li < x?size_li:x)+')').not(".disable").addClass("activemandatory");    

        //Bind Loadmore text        
        var len = $('#'+$(t).siblings("ul").attr('id')+' li.active').size() - x;
        if(len > 0)
          $("#"+$(t).siblings("div").attr('id')).text(len + 'more');
        else
          $("#"+$(t).siblings("div").attr('id')).text('');
      }
      else
      { 
        $("#"+$(t).siblings("ul").attr('id')+" .prepended").remove();  
        //Bind data based on search
        size_li = $("#"+$(t).siblings("ul").attr('id')+" li").size() - $("#"+$(t).siblings("ul").attr('id')+' li.disable').size();
        x=5;
        $('#' +$(t).siblings("ul").attr('id')+' li:not(.inactive):lt('+(size_li < x?size_li:x)+')').not(".disable").show();    

        //Bind Loadmore text        
        var len = $('#'+$(t).siblings("ul").attr('id')+' li').size() - x - $("#"+$(t).siblings("ul").attr('id')+' li.disable').size();
        if(len > 0)
          $("#"+$(t).siblings("div").attr('id')).text(len + 'more');
        else
          $("#"+$(t).siblings("div").attr('id')).text('');
      }
    },
    bindMoreFilterTags: function() {
      var loop_length = $('.swatch-list').length;    
      for(i=0;i<=loop_length;i++)
      {
        $("#list_filter"+i).addClass('list_filter_grp');
        size_li = $("#list_filter"+i+" li").size() - $("#list_filter"+i+" li.disable").size();


        $('#list_filter' +i+' li:not(.disable):lt('+(size_li < x?size_li:x)+')').not(".disable").show();    
      }
    },
    showMoreFilterTags: function() {
     
      var loop_length = $('.swatch-list').length;
      for(i=0;i<=loop_length;i++)
      { 
        var len = $('ul#list_filter'+i+' li').size() - x - $('ul#list_filter'+i+' li.disable').size();
        if(len > 0)
          $("#loadMore"+i).text(len + 'more');        
        //for show/hide search textbox
        if($('ul#list_filter'+i+' li').size() - $('ul#list_filter'+i+' li.disable').size() > x)
          $('ul#list_filter'+i).siblings('.search_tag').show();
        else
          $('ul#list_filter'+i).siblings('.search_tag').hide();
      }

    },
    updateCartInfo: function(a, b){
      var c = $(b)
      , d = $(".icon-cart-header .price")
      , e = $(".header_cart .cart-total");
      c.length && (c.empty(),
                   d.empty(),
                   $.each(a, function(b, f) {
        if ("items" == b) {
          var g = "";
          f.length ? (d.html(Shopify.formatMoney(a.total_price, money_format)),
                      e.html('<span class="cart-qty">' + a.item_count + "</span><span>" + cartData.totalNumb + "</span>"),
                      g += '<div class="cart-content">',
                      $(".header-container").hasClass("type20") && (g += '<div class="total-count">',
                                                                    g += "<span>" + a.item_count + " " + cartData.totalNumb + "</span>",
                                                                    g += '<a href="/cart" class="btn-button view-cart bordered uppercase"><span>' + cartData.buttonViewCart + "</span></a>",
                                                                    g += "</div>"),
                      g += '<ul class="clearfix">',
                      $.each(f, function(a, b) {
            g += '<li class="item-cart">',
              g += '<a class="product-image" href="' + b.url + '"><img alt="' + SW.collection.translateText(b.title) + '" src="' + Shopify.resizeImage(b.image, "small") + '" /></a>',
              g += '<div class="product-details row-fluid show-grid">',
              g += '<p class="product-name"><a href="' + b.url + '"><span>' + SW.collection.translateText(b.title) + "</span></a></p>",
              g += '<div class="items"><span class="price">' + b.quantity + ' × <span class="amount">' + Shopify.formatMoney(b.price, money_format) + "</span></span></div>",
              g += '<div class="access"><a href="javascript: void(0);" class="btn-remove btn-remove-cart" data-id="' + b.variant_id + '" title="' + cartData.removeItemLabel + '"><i class="icon-cancel"></i></a></div>',
              g += "</div>",
              g += "</li>"
          }),
                      g += "</ul>",
                      g += "</div>",
                      g += '<div class="cart-checkout">',
                      g += '<div class="cart-info"><p class="subtotal"><span class="label"> <strong>' + cartData.totalLabel + '</strong></span><span class="price">' + Shopify.formatMoney(a.total_price, money_format) + "</span></p></div>",
                      g += '<div class="actions">',
                      $(".header-container").hasClass("type20") ? g += '<a href="/checkout" class="btn-button btn checkout-cart bordered uppercase"><span>' + cartData.buttonCheckout + "</span></a>" : (g += '<a href="/cart" class="btn-button btn view-cart bordered uppercase"><span>' + cartData.buttonViewCart + "</span></a> ",
                    g += '<a href="/checkout" class="btn-button btn checkout-cart bordered uppercase"><span>' + cartData.buttonCheckout + "</span></a>"),
                      g += "</div>",
                      g += "</div>") : (d.html(Shopify.formatMoney(a.total_price, money_format)),
                                        e.html('<span class="cart-qty">' + a.item_count + "</span><span>" + cartData.totalNumb + "</span>"),
                                        g += '<div class="cart-content">',
                                        g += '<p class="no-items-in-cart">' + cartData.noItemLabel + "</p>",
                                        g += "</div>")
        }
        c.append(g)
      })),
        $(".header_cart .count").length > 0 && $(".header_cart .count").html(a.item_count),
        frontendData.enableCurrency && (currenciesCallbackSpecial("span.money"),
                                        currenciesCallbackSpecial(".icon-cart-header span.money"))
    },
    removeCartInit: function(a, b) { 
      $.ajax({
        type: "POST",
        url: "/cart/change.js",
        data: "quantity=0&id=" + a,
        dataType: "json",
        beforeSend: function() {
          $(".cartloading").show()
        },
        success: function(a) {
          "function" == typeof b ? b(a) : (SW.collection.updateCartInfo(a, ".cart-container .cart-inner-content"),
                                           $(".cartloading").hide())
        },
        error: function(a, b) {
          Shopify.onError(a, b)
        }
      })},                                              
  };  
  SW.productMediaManager = { 
    destroyZoom: function() {
      $('.zoomContainer').remove();
      $('.product-image-gallery .gallery-image').removeData('elevateZoom');
    },
    createZoom: function(a){                         
      if (SW.productMediaManager.destroyZoom(),
          !(isMobileAlt || a.length <= 0)) {
        if (a[0].naturalWidth && a[0].naturalHeight) {
          var b = a[0].naturalWidth - a.width() - imageZoomThreshold
          , c = a[0].naturalHeight - a.height() - imageZoomThreshold;
          if (b < 0 && c < 0)
            return void a.parents(".product-image").removeClass("zoom-available");
          a.parents(".product-image").addClass("zoom-available")
        }
        "inside" == dataZoom.position ? a.elevateZoom({
          zoomType: "inner",
          easing: !0,
          cursor: "crosshair"
        }) : a.elevateZoom({
          zoomWindowPosition: 1,
          easing: !0,
          zoomWindowFadeIn: 500,
          zoomWindowFadeOut: 500,
          lensFadeIn: 500,
          lensFadeOut: 500,
          borderSize: 3,
          lensBorderSize: 2,
          lensBorderColour: "#999",
          borderColour: "#ddd"
        })

      }
    },
    swapImage: function(targetImage,targetzoom) {
      SW.productMediaManager.destroyZoom();
      $('#image-main').attr('src',targetImage);
      $('#image-main').data('zoom-image',targetzoom);                                                    
      setTimeout(function(){  SW.productMediaManager.createZoom($("#image-main")); }, 1000);
    },
    swapImageForType3: function(variant) {
      var pos = 1;
      $("#prdslider").children().each(function(i){
        if ($(this).find('img').attr('src') == variant.featured_image.src.replace('https:','')){
          pos = i;
          return false;
        }
      });
      $(".flexslider").data('flexslider').flexAnimate(pos, true);
    },
    wireThumbnails: function() {
      //trigger image change event on thumbnail click
      $('.product-image-thumbs .thumb-link').click(function(e) {
        e.preventDefault();
        var jlink = $(this); 
        var target = jlink.data('image');
        var targetzoom = jlink.data('zoom-image');
        SW.productMediaManager.swapImage(target,targetzoom);
      });
    },   
    initZoom: function() {
      SW.productMediaManager.createZoom($(".gallery-image.visible")); //set zoom on first image
    }, 
    init: function() {
      $('.template-product .zoomContainer').css('z-index',6);
      SW.productMediaManager.imageWrapper = $('.product-img-box');
      // Re-initialize zoom on viewport size change since resizing causes problems with zoom and since smaller 
      $(window).resize( function() {
        SW.productMediaManager.initZoom();
      });
      SW.productMediaManager.initZoom(); 
      SW.productMediaManager.wireThumbnails();      
    }
  }; 
  SW.onReady = {
    init: function() {

      moneyformat = Currency.money_format[Currency.currentCurrency]
      x=4;
      if (
        $(".product-deal .product-date").length > 0) {
        var a = $(".product-date");
        a.each(function() {
          SW.collection.productDealInit($(this))
        })
      }
      SW.header.init(),            
        SW.collection.init() ,      
        $(document).on("click", ".btn-remove-cart", function(a) {
        a.preventDefault(),
          SW.collection.removeCartInit($(this).data("id"))
      }),
        $(document).on("click", ".clear", function(a) {    
       
        var c = [];
        Shopify.queryParams.constraint && (c = Shopify.queryParams.constraint.split("+"))
        $('.'+$(this).data('id').replace(' ','_').toLowerCase()+' ul:first li').find("input").each(function() {
          var a = jQuery(this)
          , b = a.val()
          ,p = a.data('parent').replace(" ","_"); 
          if (b) {
            var d = c.indexOf(b);
            d >= 0 && c.splice(d, 1)
            $('#dvshopping .fill_value[name="'+p+'"]').parents('.select_category').remove();
          }
  
        }),
          c.length ? Shopify.queryParams.constraint = c.join("+") : delete Shopify.queryParams.constraint,
          SW.collection.filterAjaxRequest();

        

        if($('#dvshopping').find('.select_category').length <= 0 )
        {
          $('.claerall').css('display','none');
          $("#dvfilter").css("display", "none");
        }
        else
        {
          $('.claerall').css('display','block');
          $("#dvfilter").css("display", "block");
        }
      }),
        $(document).on("click", ".claerall", function(a) {
        var c = [];
        $('#dvshopping').html('');
        $('.claerall').css('display','none');
        
        c.length ? Shopify.queryParams.constraint = c.join("+") : delete Shopify.queryParams.constraint,
        
          SW.collection.filterAjaxRequest()            
        $("#dvfilter").css("display", "none");
      }),
        $(document).on("keyup", ".searchtag", function(event) { 
        SW.collection.searchfiltertag($(this).data('id'),$(this).val(),$(this).parents('.showmore_header').siblings('.tags_content').children('.tags_content_ul'));
      }),
        $(document).on("keyup", ".search_tag", function(event) {
        SW.collection.searchsepratetag($(this));
      }),    
        $(document).on("click", ".clearfilter", function(a) {
     
        var clval = $(this).data('val');
        var c = [];
        Shopify.queryParams.constraint && (c = Shopify.queryParams.constraint.split("+"))

        if($(this).data('id') != '')
          var p =$('.'+$(this).data('id').replace(' ','_').toLowerCase()).find("input[value='"+clval+"']").data('parent').replace(" ","_");
        else
          var p = '';

        $('a[data-val='+ clval +']').parents('p').remove();
//         if($('#dvshopping div[name="'+p+'"]').length <= 0){
//           $('#dvshopping div[name="'+p+'"]').remove();  
//         }
        
        if($('.fill_value[name="'+p+'"] p').length <= 0){
        	 $('#dvshopping .fill_value[name="'+p+'"]').remove();
        }
        if( $('#dvshopping .fill_value[name="'+p+'"]').length <= 0){
        	 $('#dvshopping div[name="'+p+'"]').parents('.select_category').remove();
        }
        
        if($.trim($('#dvshopping').html()) == ''){
          $('.claerall').css('display','none');
          $("#dvfilter").css("display", "none");
        }
        else
        {
          $("#dvfilter").css("display", "block");
        }
        var v = clval.toString();
        var d = c.indexOf(v);
        d >= 0 && c.splice(d, 1)
        c.length ? Shopify.queryParams.constraint = c.join("+") : delete Shopify.queryParams.constraint,
          SW.collection.filterAjaxRequest();
      }),
        $(document).on("click", ".collection_sidebar .loadMore", function(a) { 
        $(this)
        .hide()
        .siblings('ul.swatch-list').find('li').show();

      }),        
        $(document).on("click", ".filter_icon", function(a){
        $(this).parents('.block-title').siblings('.layer-filter').slideToggle();
        $(this).parents('.block-title').toggleClass('open-filter');
      }),
        $(document).on("click", ".grid-list", function(a){
        var grid = $(this).data('column');
        $(this).addClass('active');
        $(this).siblings('.grid-list').removeClass('active');                                   
        $('ul.category-products div.product').removeClass("column2 column3 column4")
        .addClass(grid);
        equalheight('.equalheight');
        var pro_img = $('.pro_image').outerWidth(); $('.pro_image').css('height',pro_img);
      }),
        $( document ).on( "mouseenter",".item-effect-style1" ,function( event ) { 
        var h = $(this).css('height');
        $(this).css('height',h);
        $(this).addClass('hover');
      }),
        $( document ).on( "mouseleave",".item-effect-style1" ,function( event ) {
        $(this).removeAttr("style");
        $(this).removeClass('hover');
      }),      
        SW.collection.countDowntInit(),
        SW.collection.quickshopInit(),
        SW.collection.bindMoreFilterTags(),      
        SW.productMediaManager.init()
    }
  },
    SW.onLoad = {

    init: function() {}
  },
    $(document).ready(function() {

    SW.onReady.init()
  }),
    $(window).load(function() {
    SW.onLoad.init(),
      SW.collection.showMoreFilterTags()
  })
}(jQuery);

$(document).ready(function() {
  var sections = new slate.Sections();
  sections.register('product', theme.Product);

  // Common a11y fixes
  slate.a11y.pageLinkFocus($(window.location.hash));

  $('.in-page-link').on('click', function(evt) {
    slate.a11y.pageLinkFocus($(evt.currentTarget.hash));
  });

  // Target tables to make them scrollable
  var tableSelectors = '.rte table';

  slate.rte.wrapTable({
    $tables: $(tableSelectors),
    tableWrapperClass: 'rte__table-wrapper',
  });

  // Target iframes to make them responsive
  var iframeSelectors =
      '.rte iframe[src*="youtube.com/embed"],' +
      '.rte iframe[src*="player.vimeo"]';

  slate.rte.wrapIframe({
    $iframes: $(iframeSelectors),
    iframeWrapperClass: 'rte__video-wrapper'
  });

  // Apply a specific class to the html element for browser support of cookies.
  if (slate.cart.cookiesEnabled()) {
    document.documentElement.className = document.documentElement.className.replace('supports-no-cookies', 'supports-cookies');
  }


  addToWishlist();
  addToCartFromDetail();
});

/* ===================================
     Start Variant Change Event
     ====================================== */
$(document).on('change', '.single-option-selector', function() {

  //first get data and all current options in this product section
  var product = $(this).parents('section.section-product').length>0 ? $(this).parents('section.section-product') : $(this).parents("div.product_page"),  qty=parseInt($('#Quantity').val());
  var sectionId = product.attr('data-section-id');    
  var pid=(product.attr('data-pid')==undefined) ? "" : "-"+product.attr('data-pid');
  sectionId=sectionId+pid;

  var productData= JSON.parse(document.getElementById('ProductJson-' + sectionId).innerHTML);
  var selector = product.find('.product-form__variants');

  var variants = productData.variants;
  var money_format = product.data('money-format');

  var variant = findVariantFromOptions(product,variants);

  if (typeof variant === "undefined")
  {
    if($('body')[0].className.indexOf("product") >= 0)
    {

      $(product)
      .find("#addToCart"+sectionId)
      .attr('disabled', 'disabled').fadeTo(200,0.5)
      .children('span').text(theme.strings.unavailable);      
    }
    else if($(product).hasClass('wish_list_variants'))
    {
      $(product)
      .parents('td.unit-options')
      .siblings('td.product-action')
      .find("#addToCart"+sectionId)
      .attr('disabled', 'disabled').fadeTo(200,0.5)
      .removeClass('js-add-to-cart');
    }
    else
    {
      $(product)
      .find("#addToCart"+sectionId)
      .hide()
      .end()
      .find(".mobile_show #addToCart"+sectionId)
      .hide();
    }
    if($('.pro_dec_right .stock').length > 0)
    {
      $('p.stock')
      .text(window.inventory_text.out_of_stock)
      .removeClass('out_stock in_stock')
      .addClass('out_stock');
    }
    $('.sku_no').text('sku: -');
  }
  else
  {    
    var prdid = (product.attr('data-pid')==undefined) ? "" : product.attr('data-pid');

    $("#prdprice"+sectionId).html('<span class="money">'+convertCurrency(variant.price)+'</span>');
	if (variant.compare_at_price > 0 )
    {
    	$("#cmpprice"+sectionId).html('<span class="money">'+convertCurrency(variant.compare_at_price) +'</span>');
    }
    $(selector).find('option[value="' + variant.id +'"]').prop('selected', true);
    $('.sku_no').text(('' == variant.sku ? 'sku: -' : 'sku: '+variant.sku));
    if(variant.inventory_management == 'shopify' && variant.inventory_policy == 'deny')
    {
      if(variant.inventory_quantity > 0)
      {          
        $(product)
        .find("#addToCart"+sectionId)          
        .removeAttr('disabled').fadeTo(200,1)          
        .show()
        .children('span').text(theme.strings.addToCart)
        .end()
        .find(".mobile_show #addToCart"+sectionId)          
        .show()
        .children('span').text(theme.strings.addToCart);

        if($(product).hasClass('wish_list_variants'))
        {
          $(product)
          .parents('td.unit-options')
          .siblings('td.product-action')
          .find("#addToCart"+sectionId)
          .removeAttr('disabled').fadeTo(200,1)
          .addClass(' js-add-to-cart');

          $(product).find("select[name=id]").val(variant.id);
        }

        if($('.stock').length > 0)
        {
          $('p.stock')
          .text(window.inventory_text.in_stock)
          .removeClass('out_stock in_stock')
          .addClass('in_stock');
        }
      }
      else
      {
        if($('body')[0].className.indexOf("product") >= 0)
        {
          $(product)
          .find("#addToCart"+sectionId)
          .attr('disabled', 'disabled').fadeTo(200,0.5)
          .children('span').text(theme.strings.soldOut);
        }
        else if($(product).hasClass('wish_list_variants'))
        {
          $(product)
          .parents('td.unit-options')
          .siblings('td.product-action')
          .find("#addToCart"+sectionId)
          .attr('disabled', 'disabled').fadeTo(200,0.5)
          .removeClass('js-add-to-cart');
        }
        else
        {
          $(product)
          .find("#addToCart"+sectionId)
          .hide()
          .children('span').text(theme.strings.soldOut)
          .end()
          .find(".mobile_show #addToCart"+sectionId)            
          .hide()
          .children('span').text(theme.strings.soldOut);
        }
        if($('.stock').length > 0)
        {
          $('p.stock')
          .text(window.inventory_text.out_of_stock)
          .removeClass('out_stock in_stock')
          .addClass('out_stock');
        }
      }
    }
    else
    {
      $(product)
      .find("#addToCart"+sectionId)          
      .removeAttr('disabled').fadeTo(200,1)          
      .show()
      .children('span').text(theme.strings.addToCart)
      .end()
      .find(".mobile_show #addToCart"+sectionId)          
      .show()
      .children('span').text(theme.strings.addToCart);

      if($(product).hasClass('wish_list_variants'))
      {
        $(product)
        .parents('td.unit-options')
        .siblings('td.product-action')
        .find("#addToCart"+sectionId)
        .removeAttr('disabled').fadeTo(200,1)
        .addClass(' js-add-to-cart');

        $(product).find("select[name=id]").val(variant.id);
      }
      if($('.stock').length > 0)
      {
        $('p.stock').text(window.inventory_text.in_stock);
        $('p.stock').removeClass('out_stock').removeClass('in_stock').addClass('in_stock');
      }
    }
  }

  //update history - dont update if homepage
  if (history.replaceState && $('body').hasClass('template-product')) {
    if (typeof variant != "undefined")
    {
      var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?variant=' + variant.id;
      window.history.replaceState({path: newurl}, '', newurl);
    }
  }
});

function findVariantFromOptions(parentDiv,variants)
{

  var options = [];
  parentDiv.find('.single-option-selector').each(function() {
    var option = $(this).data('index');
    var val = $(this).val();        
    var selected = {
      "index": option,
      "value": val
    }
    options.push(selected);
  });
  var variant = _.find(variants, function(variant) {
    return options.every(function(values) {
      return _.isEqual(variant[values.index], values.value);
    });
  });

  return variant;
}

/* ===================================
     End Variant Change Event
     ====================================== */
function addToCartFromDetail()
{  
  $('.add-tocart-submit').on('click', function(){


    var cartbtn = $(this),parentDiv = cartbtn.parents('.product_content'),errorSpan = parentDiv.find('.variant_msg');
    var form = cartbtn.closest('form'), c_check = true;

    var prodList = $(":root").find('[id^=ProductJson]');
    var productData = JSON.parse(prodList[prodList.length-1].innerHTML);
    console.log("Product data");
    console.log(productData);
    
    var qty=parseInt($('#Quantity').val(),productData);
    var variants = productData.variants;
    
    errorSpan.text("").css("display","none").removeClass("stock_msg"); 
    var selectedVariant = findVariantFromOptions(parentDiv,variants);

    console.log(selectedVariant);
    console.log(selectedVariant.inventory_policy);
    console.log(selectedVariant.inventory_quantity);
    if(selectedVariant.inventory_quantity > 0 && selectedVariant.inventory_management =="shopify" && selectedVariant.inventory_policy == "deny")
    {
      c_check = checkItemInCart(selectedVariant,errorSpan,qty);
    }

	if(c_check)    
     form.submit();
  });
}


function checkItemInCart(variant,errorSpan,qty)
{
  var valid = true;
  $.ajax({
    type: "GET",
    url: "/cart.js",
    async: 0,
    dataType: "json",
    success: function(cart) {       
      var cartitems= cart.items;
      var item =_.find(cartitems, function(item) {return item.id == variant.id; });
      var str = window.inventory_text.maximum_qty, res = str.replace("{{qty}}",variant.inventory_quantity);          
      if(item != null ){
        if(parseInt(item.quantity) >= parseInt(variant.inventory_quantity)){          
          valid= false;
          errorSpan.addClass("stock_msg").css("display","table")
          .text(window.inventory_text.added_maximum_qty); 

          errorSpan.wrapInner('<span></span>');
        }
        else if (variant.inventory_quantity < qty || (parseInt(item.quantity)+parseInt(qty))>variant.inventory_quantity ){               
          valid= false;
          errorSpan.addClass("stock_msg")
          .css("display","table")
          .text(res);

          errorSpan.wrapInner('<span></span>');
        }
      }
      else if(variant.inventory_quantity < qty)
      {
        valid= false;
        errorSpan.addClass("stock_msg")
        .css("display","table")
        .text(res);

        errorSpan.wrapInner('<span></span>');
      }

    },
    error: function(t) {
      valid= false;
    }
  });

  if(valid)
    errorSpan.text("").css("display","none"); 

  return valid;
}

//============= Wishlist Functions ========================
function addToWishlist() {  
  $(document).on('click', '.add-to-wishlist', function(e) 
                 {
    e.preventDefault();
    email=$(this).data('email');
    tags=$(this).data('tags');

    var params = [];                  
    params.push(['contact[email]', email]);
    params.push(['contact[tags]', tags]);
    params.push(['form_type', 'customer']);
    params.push(['utf8', '✓']);

    var form = document.createElement("form");

    form.setAttribute("method", "post");
    form.setAttribute("action", "/contact");

    for (var key in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", params[key][0]);
      hiddenField.setAttribute("value", params[key][1]);
      form.appendChild(hiddenField);
    }
    document.body.appendChild(form);
    form.submit();
  });
}

function removeFromWishlist($this) 
{
  // get the id which is the selected variant tag
  var tagID = $this.data('id');
  var $form = $("#remove");
  // set the value of the input in the form to the selected variant
  $("#remove-value").attr("value", tagID);
  var formURL = $form.attr("action");
  $("#remove").submit();
}

$(".js-remove-button").on("click", function(e) {
  e.preventDefault();
  removeFromWishlist($(this));
});
$(document).on('click', '.js-add-to-cart', function(e) 
               {
  e.preventDefault();
  if($(this).hasClass('js-add-to-cart'))
  {    
    var variantdiv = $(this).parents('.product-action').siblings('.unit-options').children('.product_page'),
        variantID = variantdiv.find("select[name='id']").val();
    var quantity = $(this).parents('.product-action').siblings('.unit-quantity').children('.pro_qty').find("input.unit-quantity").val();
    var errorSpan = variantdiv.find('.variant_msg'), c_check = true;

    var productData= JSON.parse(variantdiv.find('[id*="ProductJson"]')[0].innerHTML);
    var variants = productData.variants;

    var selectedVariant = findVariantFromOptions(variantdiv,variants);

    if(selectedVariant.inventory_quantity > 0 && selectedVariant.inventory_management =="shopify" && selectedVariant.inventory_policy == "deny")
    {
      c_check = checkItemInCart(selectedVariant,errorSpan,quantity);
    }

    if(c_check)
    {
      $('#product-select').attr("value", variantID);
      $('#product-quantity').attr("value", quantity);
      // uncomment next line if you want a product to be removed from the wish list when it is added to the cart
      //removeFromWishlist($(this));
      $('#add-variant').submit();
    }
  }
});

//================== End Wishlist Functions ==================

/* ================= For the QuickView selectOptionCallback===================================== */
var selectOptionCallback = function(variant, selector) {      

  quickShopStock.html('').removeClass('out_stock in_stock');  

  if (variant && ((variant.inventory_quantity > 0 && variant.inventory_management == 'shopify') || variant.inventory_management == null ) ) {                  
    quickShopAddToCartButton.data('variant-id', variant.id)
    .removeAttr('disabled').fadeTo(200,1)
    .children('span').text(theme.strings.addToCart);

    quickShopSku.html('' == variant.sku ? 'sku: -' : 'sku: '+ variant.sku);
    quickShopStock.addClass('in_stock').html(window.inventory_text.in_stock);

    if ( variant.compare_at_price > 0 && variant.compare_at_price > variant.price ) {          
      quickShopContainer.find('#prdprice').html(convertCurrency(variant.price));
    } else {
      quickShopContainer.find('#prdprice').html(convertCurrency(variant.price));
    };

  } else {
    // variant doesn't exist
    quickShopAddToCartButton.attr('disabled', 'disabled').fadeTo(200,0.5)
    .children('span').text(theme.strings.unavailable);    
    quickShopStock.addClass('out_stock').html(window.inventory_text.out_of_stock);
  }

  //change image
  if (variant && variant.featured_image) {
    var newImage = variant.featured_image; // New image object.
    var mainImageDomEl = jQuery('#dvmoalcontent .product-img-box .pro_img_main img')[0]; // DOM element of main image we need to swap.
    Shopify.Image.switchImage(newImage, mainImageDomEl, switchImage); 
    SW.productMediaManager.createZoom($(".gallery-image.visible"));
  }
  // Update currency
  currenciesCallbackSpecial('#quick-shop-modal span.money');
}